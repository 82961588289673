import React from 'react';
import './App.css';
import Loader from './Loader';
import GameBoard, { GameInfo } from './GameBoard';
import TutorialTips from './TutorialTip';
import { complexTutorial } from './tutorials';

interface AppState {
  tutorialMode: number,
  tutorialState: number,
  gameSettings: number[],
  game: GameInfo,
}

class App extends React.Component<{}, AppState> {

  boardRef = React.createRef<GameBoard>();

  state = {
    tutorialMode: 0,
    tutorialState: 0,
    gameSettings: [0, 1, 0],
    game: {
      hash: "",
      code: 0,
      m: 0,
      d: 0,
      n: 0,
      ind: [] as number[],
      fake: [] as number[],
      law: [] as number[],
      exCards: [] as number[][],
      nmCards: [] as number[],
    }
  }

  render() {

    return (
      <div className="App">
        <Loader
          hash={this.state.game.hash}
          gameSettings={this.state.gameSettings}
          clickUpdate={(column, row) => this.clickGameSettings(column, row)}
          playGame={() => this.playGame()}
          tutorialGame={() => this.tutorialGame()}
        />
        <TutorialTips
          mode={this.state.tutorialMode}
          st={this.state.tutorialState}
          incSt={() => this.incTutorialSt()}
        />
        <GameBoard
          game={this.state.game}
          ref={this.boardRef}
          tutorialMode={this.state.tutorialMode}
          tutorialState={this.state.tutorialState}
          tutorialStep={() => this.incTutorialSt()}
        />
      </div>
    );
  }

  clickGameSettings(column: number, row: number) {
    this.state.gameSettings[column] = row;
    this.forceUpdate();
  }

  setGame(hash: string, code: number, m: number, d: number, n: number, ind: number[], law: number[], fake: number[]) {
    this.state.game.hash = hash;
    this.state.game.code = code;
    this.state.game.m = m;
    this.state.game.d = d;
    this.state.game.n = n;
    this.state.game.ind = ind;
    this.state.game.law = law;
    this.state.game.fake = fake;
  }

  tutorialGame() {
    this.boardRef.current?.clearState();

    if (this.state.gameSettings[0] === 0 && this.state.gameSettings[1] !== 2) {
      this.setGame("A43 Y6L ", 241, 0, 0, 4, [4, 9, 11, 14], [9, 46, 94, 118], []);
      this.state.tutorialMode = 1;
      this.state.tutorialState = 0;
    } else {
      this.state.tutorialMode = 2;
      this.state.tutorialState = 0;
    }

    this.forceUpdate();
  }

  playGame() {
    this.boardRef.current?.clearState();
    this.state.tutorialMode = 0;
    this.state.tutorialState = 0;
    this.loadGame(`m=${this.state.gameSettings[0]}&d=${this.state.gameSettings[1]}&n=${this.state.gameSettings[2] + 4}`);
  }

  loadGame(url: string) {
    var xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      var data = xhr.responseText;
      if (data.length === 0) {
        return;
      }

      var jsonResponse = JSON.parse(data);

      this.state.game.hash = jsonResponse["hash"];
      this.state.game.code = jsonResponse["code"];
      this.state.game.m = Number(jsonResponse["m"]);
      this.state.game.d = jsonResponse["d"];
      this.state.game.n = jsonResponse["n"];
      this.state.game.ind = jsonResponse["ind"];
      this.state.game.fake = jsonResponse["fake"];
      this.state.game.law = jsonResponse["law"];

      if (this.state.game.m === 1) {
        this.state.game.exCards = [];
        for (let i = 0; i < this.state.game.n; i++) {
          const ind = this.state.game.ind[i];
          const fake = this.state.game.fake[i];
          this.state.game.exCards.push(this.shuffleCards(ind, fake));
        }
      } else if (this.state.game.m === 2) {
        this.sortInd();
      }

      this.forceUpdate();
    });
    xhr.addEventListener("error", () => {
      console.log("error");
    });
    xhr.addEventListener("abort", () => {
      console.log("abort");
    });

    xhr.open(
      "GET",
      "https://turingmachine.info/api/api.php?" + url
    );
    xhr.send();
  }

  shuffleCards(c1: number, c2: number): number[] {
    if (Math.floor(Math.random() * 2) === 0) return [c1, c2];
    else return [c2, c1];
  }

  sortInd() {
    this.state.game.nmCards = [...this.state.game.ind];
    this.state.game.nmCards.sort((a, b) => (a > b ? 1 : -1));
  }

  incTutorialSt() {
    if (this.state.tutorialMode === 1) {
      if (this.state.tutorialState === 28) return;

      this.state.tutorialState += 1;
      this.forceUpdate();
    } else if (this.state.tutorialMode === 2) {
      if (this.state.tutorialState === complexTutorial.length - 1) return;

      if (this.state.tutorialState === 2) {
        this.state.gameSettings = [0, 2, 2];
        this.boardRef.current?.clearState();
        this.setGame("C63 42O 9 ", 411, 0, 2, 6, [11, 22, 30, 33, 34, 40], [92, 135, 4, 38, 130, 31], []);
      }

      if (this.state.tutorialState === 13) {
        this.state.gameSettings = [1, 0, 0];
        this.boardRef.current?.clearState();
        this.setGame("D44 1ZG ", 341, 1, 0, 4, [4, 17, 11, 14], [9, 86, 94, 118], [25, 20, 6, 3]);
        this.state.game.exCards = [[25, 4], [20, 17], [6, 11], [3, 14]];
      }

      if (this.state.tutorialState === 16) {
        this.state.gameSettings = [2, 0, 0];
        this.boardRef.current?.clearState();
        this.setGame("G43 Y9N ", 142, 2, 0, 4, [9, 14, 13, 4], [46, 116, 95, 9], []);
        this.state.game.nmCards = [4, 13, 14, 9];
      }

      this.state.tutorialState += 1;
      this.forceUpdate();
    }
  }

}

export default App;
