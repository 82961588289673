import { Component } from "react";
import './Gameboard.css'
import InputCode from "./InputCode";
import check from "./checker";
import getCodeDigit from "./getCodeDigit";
import NoteSheet from "./NoteSheet";

export type GameInfo = {
  hash: string,
  code: number,
  m: number,
  d: number,
  n: number,
  ind: number[],
  fake: number[],
  law: number[],
  exCards: (number[])[]
  nmCards: number[],
};

interface GameBoardProp {
  game: GameInfo,
  tutorialMode: number,
  tutorialState: number,
  tutorialStep: () => void,
}

// GameState
// 0: no input code
// 1: to choose a card (0/3)
// 2: to choose a card (1/3)
// 3: to choose a card (2/3)
// 4: next turn (3/3)
// 5: finished

interface GameBoardState {
  gameState: number,
  current: number,
  round: number,
  question: number,
  guess: number[],
  result: (number[] | boolean)[],
}

class GameBoard extends Component<GameBoardProp, GameBoardState> {

  tipLabels = [
    "输入一组数字以验证或提交结果",
    "选择一张验证卡（0/3），或进行下一轮。",
    "选择一张验证卡（1/3），或进行下一轮",
    "选择一张验证卡（2/3），或进行下一轮",
    "你必须进行下一轮（3/3）",
    "游戏结束！"
  ]

  state = {
    gameState: 0,
    current: 0,
    round: 0,
    question: 0,
    error: 0,
    guess: [] as number[],
    result: [] as (number[] | boolean)[],
  }

  clearState() {
    this.setState((_) => {
      return {
        gameState: 0,
        current: 0,
        round: 0,
        question: 0,
        error: 0,
        guess: [] as number[],
        result: [] as (number[] | boolean)[]
      }
    })
  }

  showResult(label: string) {
    console.log(label);
  }

  invalidCode(code: number): boolean {
    return getCodeDigit(code).indexOf(0) !== -1;
  }

  askCode(code: number) {
    if (this.invalidCode(code)) return;
    if (this.props.tutorialMode === 1) {
      if (this.props.tutorialState === 5 && code === 452) {
        this.props.tutorialStep();
      } else if (this.props.tutorialState === 15 && code === 142) {
        this.props.tutorialStep();
      } else {
        return;
      }
    } else if (this.props.tutorialMode === 2) {
      if ([6, 15, 18].indexOf(this.props.tutorialState) === -1)
        return;
    }

    this.state.current = code;
    this.state.round += 1;
    this.state.gameState += 1;
    this.state.result.push(new Array(Number(this.props.game.n)).fill(0));
    this.state.guess.push(code);
    this.forceUpdate();
  }

  submitCode(code: number) {
    if (this.invalidCode(code)) return;
    if (this.props.tutorialMode === 1) {
      if (this.props.tutorialState === 21 && code === 241) {
        this.props.tutorialStep();
      } else {
        return;
      }
    } else if (this.props.tutorialMode === 2) {
      return;
    }

    this.state.guess.push(code);

    if (code === this.props.game.code) {
      this.state.result.push(true);
      this.state.gameState = 5;
    } else {
      this.state.result.push(false);
      this.state.error += 1;
    }

    this.forceUpdate();
  }

  nextRound() {
    if (this.props.tutorialMode === 1) {
      if ([14, 19].indexOf(this.props.tutorialState) === -1) {
        return;
      } else {
        this.props.tutorialStep();
      }
    } else if (this.props.tutorialMode === 2) {
      if ([6, 15, 18].indexOf(this.props.tutorialState) === -1)
        return;
    }

    this.state.gameState = 0;
    this.forceUpdate();
  }

  clickCard(i: number) {
    if (this.state.gameState === 0 || this.state.gameState === 4) return;
    if (this.props.tutorialMode === 1) {
      let b = false;
      if (this.props.tutorialState === 7 && i === 0) b = true;
      if (this.props.tutorialState === 8 && i === 1) b = true;
      if (this.props.tutorialState === 9 && i === 2) b = true;
      if (this.props.tutorialState === 16 && i === 3) b = true;
      if (!b) return;
      this.props.tutorialStep();
    } else if (this.props.tutorialMode === 2) {
      if ([6, 15, 18].indexOf(this.props.tutorialState) === -1)
        return;
    }

    const code = this.state.current;
    if ((this.state.result[this.state.round - 1] as number[])[i] !== 0) return;

    const law = this.props.game.law[i];
    const res = check(code, law);

    (this.state.result[this.state.round - 1] as number[])[i] = res ? 1 : -1;
    this.state.question += 1;
    this.state.gameState += 1;
    this.forceUpdate();
  }

  render() {
    if (this.props.game.ind.length === 0) return;

    let cds = <></>;

    if (this.props.game.m == 0) {
      cds = this.renderClassic();
    } else if (this.props.game.m == 1) {
      cds = this.renderExtreme();
    } else {
      cds = this.renderNightmare();
    }

    // Generate Tips
    let nextTurn = this.state.gameState === 0 || this.state.gameState === 5 ? <></> :
      <input
        type="button"
        onClick={() => this.nextRound()}
        value="下一轮"
      />

    // Generate InputCode
    let inputCode = this.state.gameState !== 0 ? <div /> :
      <InputCode
        submitCode={(code: number) => this.submitCode(code)}
        askCode={(code: number) => this.askCode(code)}
      />

    return (
      <div>
        {cds}
        <div className="tip">
          <label>{this.tipLabels[this.state.gameState]}</label>
          {nextTurn}
        </div>
        <br />
        <div className="player-area">
          {inputCode}
          <NoteSheet
            guess={this.state.guess}
            result={this.state.result}
            n={this.props.game.n}
          />
        </div>

      </div>
    )
  }

  renderClassic(): JSX.Element {

    const labels = ["A", "B", "C", "D", "E", "F"];
    const cds = [];

    for (let i = 0; i < this.props.game.n; i++) {
      const card = this.props.game.ind[i];
      const path = `./cards/GameCards-${card}.png`
      cds.push(
        <div className="card-cell" key={card} >
          <div className="card-label">{labels[i]}</div>
          <img
            src={path} alt="error"
            onClick={() => this.clickCard(i)}
          />
        </div>
      )
    };

    return (<div className="card-table">{cds}</div>);
  }

  renderExtreme(): JSX.Element {

    const labels = ["A", "B", "C", "D", "E", "F"];
    const cds = [];

    for (let i = 0; i < this.props.game.n; i++) {
      const [card1, card2] = this.props.game.exCards[i];
      const path1 = `./cards/GameCards-${card1}.png`
      const path2 = `./cards/GameCards-${card2}.png`
      cds.push(
        <div className="card-ex" key={i} onClick={() => this.clickCard(i)} >
          <div className="card-ex-label">{labels[i]}</div>
          <div className="card-ex-cards">
            <div className="card-cell" key={card1} >
              <img src={path1} alt="error" />
            </div>
            <div className="card-cell" key={card2} >
              <img src={path2} alt="error" />
            </div>
          </div>
        </div>
      )
    }

    return (<div className="card-table">{cds}</div>);
  }

  renderNightmare(): JSX.Element {
    // Generate Cards
    const labels = ["A", "B", "C", "D", "E", "F"];
    const cds = [];
    const lbls = [];

    for (let i = 0; i < this.props.game.n; i++) {
      const card = this.props.game.nmCards[i];
      const path = `./cards/GameCards-${card}.png`
      cds.push(
        <div className="card-cell" key={card} >
          <img src={path} alt="error" />
        </div>
      )

      lbls.push(<div
        className="card-label"
        key={i}
        onClick={() => this.clickCard(i)}
      >{labels[i]}</div>);
    }

    return (<>
      <div className="card-table">{cds}</div>
      <div className="card-nm-labels">{lbls}</div>
    </>);
  }
}

export default GameBoard;
