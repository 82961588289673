import { Component } from "react";
import './NoteSheet.css'
import getCodeDigit from "./getCodeDigit";

interface NoteSheetProps {
  guess: number[],
  result: (number[] | boolean)[],
  n: number,
}

class NoteSheet extends Component<NoteSheetProps> {

  render() {

    let table = [];
    let head = [];

    head.push(<div className="code" key="colors">
      <span className="blueTriangle" />
      <span className="yellowSquare" />
      <span className="purpleCircle" />
    </div>);
    for (let j = 0; j < this.props.n; ++j)
      head.push(<div className="cell default" key={j} >{["A", "B", "C", "D", "E", "F"][j]}</div>)
    table.push(<div className="row head" key="head">{head}</div>)

    for (let i = 0; i < this.props.guess.length; ++i) {
      let row = [];
      let code = getCodeDigit(this.props.guess[i]);

      row.push(<div className="code" key="code" >
        <div className="bcode">{code[0]}</div>
        <div className="ycode">{code[1]}</div>
        <div className="pcode">{code[2]}</div>
      </div>);

      let res = this.props.result[i];
      if (typeof res === "boolean") {
        if (res as boolean) {
          row.push(<div className="cell info yes" key="res">猜测正确！</div>)
        } else {
          row.push(<div className="cell info no" key="res">猜测错误！</div>)
        }
      } else {
        for (let j = 0; j < this.props.n; ++j) {
          const v = (res as number[])[j];
          if (v === 0) row.push(<div className="cell default" key={j} />)
          if (v === 1) row.push(<div className="cell yes" key={j} >✔</div>)
          if (v === -1) row.push(<div className="cell no" key={j} >✘</div>)
        }
      }

      table.push(<div className="row" key={i}>{row}</div>);
    }

    return (
      <div className="note-sheet">{table}</div>)
  }

}

export default NoteSheet;
