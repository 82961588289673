import getCodeDigit from "./getCodeDigit";

type tget = (a: number) => number

const invalid = (_: number) => false;
const b = (code: number) => getCodeDigit(code)[0];
const y = (code: number) => getCodeDigit(code)[1];
const p = (code: number) => getCodeDigit(code)[2];
// const getter
const n = (x: number) => (_: number) => x;

const eq = (g1: tget, g2: tget) => (code: number) => g1(code) === g2(code);
const gt = (g1: tget, g2: tget) => (code: number) => g1(code) > g2(code);

const countIf = (f: (x: number) => boolean) => (code: number) => {
  var c = 0;
  if (f(b(code))) c += 1;
  if (f(y(code))) c += 1;
  if (f(p(code))) c += 1;
  return c
}
const count = (x: number) => countIf((y) => x === y);

const sum = (code: number) => b(code) + y(code) + p(code);

const laws: ((a: number) => boolean)[] = [
  invalid,
  // 1 - 15
  eq(b, n(1)), invalid, eq(b, n(3)), eq(b, n(4)), eq(b, n(5)),
  eq(y, n(1)), invalid, eq(y, n(3)), eq(y, n(4)), eq(y, n(5)),
  eq(p, n(1)), invalid, eq(p, n(3)), eq(p, n(4)), eq(p, n(5)),
  // 16 - 24
  gt(b, n(1)), invalid, gt(b, n(3)),
  gt(y, n(1)), invalid, gt(y, n(3)),
  gt(p, n(1)), invalid, gt(p, n(3)),
  // 25 - 33
  gt(n(3), b), gt(n(4), b), invalid,
  gt(n(3), y), gt(n(4), y), invalid,
  gt(n(3), p), gt(n(4), p), invalid,
  // 34 - 39
  code => b(code) % 2 === 0,
  code => y(code) % 2 === 0,
  code => p(code) % 2 === 0,
  code => b(code) % 2 === 1,
  code => y(code) % 2 === 1,
  code => p(code) % 2 === 1,
  // 40 - 54
  code => count(1)(code) === 0,
  code => count(1)(code) === 1,
  code => count(1)(code) === 2,
  invalid,
  invalid,
  invalid,
  code => count(3)(code) === 0,
  code => count(3)(code) === 1,
  code => count(3)(code) === 2,
  code => count(4)(code) === 0,
  code => count(4)(code) === 1,
  code => count(4)(code) === 2,
  invalid,
  invalid,
  invalid,
  // 55 - 60
  code => sum(code) % 2 === 0,
  code => sum(code) % 2 === 1,
  code => sum(code) % 3 === 0,
  code => sum(code) % 4 === 0,
  code => sum(code) % 5 === 0,
  code => sum(code) === 6,
  // 61 - 66
  invalid, invalid, invalid,
  invalid, invalid, invalid,
  // 67 - 80
  code => sum(code) > 6,
  invalid, invalid, invalid,
  invalid, invalid, invalid,
  code => sum(code) < 6,
  invalid, invalid, invalid,
  invalid, invalid, invalid,
  // 81, 82
  code => count(b(code))(code) !== 2 && count(y(code))(code) !== 2,
  code => count(b(code))(code) === 2 || count(y(code))(code) === 2,
  // 83, 84
  code => b(code) + 1 !== y(code) && y(code) + 1 !== p(code),
  code => (b(code) + 1 === y(code) && y(code) + 1 !== p(code))
    || (b(code) + 1 !== y(code) && y(code) + 1 === p(code)),
  // 85 - 88
  code => countIf(x => x % 2 === 0)(code) === 0,
  code => countIf(x => x % 2 === 0)(code) === 1,
  code => countIf(x => x % 2 === 0)(code) === 2,
  code => countIf(x => x % 2 === 0)(code) === 3,
  // 89 - 97
  eq(b, y), eq(b, p), eq(y, p),
  gt(b, y), gt(b, p), gt(y, b), gt(y, p), gt(p, b), gt(p, y),
  // 98 - 112
  code => b(code) + y(code) === 4, invalid,
  code => b(code) + y(code) === 6, invalid, invalid,
  code => b(code) + p(code) === 4, invalid,
  code => b(code) + p(code) === 6, invalid, invalid,
  code => p(code) + y(code) === 4, invalid,
  code => p(code) + y(code) === 6, invalid, invalid,
  // 113 - 118
  code => b(code) > p(code) && b(code) > y(code),
  code => y(code) > p(code) && y(code) > b(code),
  code => p(code) > b(code) && p(code) > y(code),
  code => b(code) < p(code) && b(code) < y(code),
  code => y(code) < p(code) && y(code) < b(code),
  code => p(code) < b(code) && p(code) < y(code),
  // 119 - 121
  code => count(b(code))(code) === 3,
  code => count(b(code))(code) === 2 || count(y(code))(code) === 2,
  code => count(b(code))(code) === 1 && count(y(code))(code) === 1,
  // 122 - 124
  code => (b(code) + 1 !== y(code) && y(code) + 1 !== p(code))
    && (b(code) - 1 !== y(code) && y(code) - 1 !== p(code)),
  code => (b(code) + 1 === y(code) && y(code) + 1 !== p(code))
    || (b(code) - 1 === y(code) && y(code) - 1 !== p(code))
    || (b(code) + 1 !== y(code) && y(code) + 1 === p(code))
    || (b(code) - 1 !== y(code) && y(code) - 1 === p(code)),
  code => (b(code) + 1 === y(code) && y(code) + 1 === p(code))
    && (b(code) - 1 === y(code) && y(code) - 1 === p(code)),
  // 125 - 130
  code => b(code) >= p(code) && b(code) >= y(code),
  code => y(code) >= p(code) && y(code) >= b(code),
  code => p(code) >= b(code) && p(code) >= y(code),
  code => b(code) <= p(code) && b(code) <= y(code),
  code => y(code) <= p(code) && y(code) <= b(code),
  code => p(code) <= b(code) && p(code) <= y(code),
  // 131 - 132
  code => countIf(x => x % 2 === 0)(code) > countIf(x => x % 2 === 1)(code),
  code => countIf(x => x % 2 === 0)(code) < countIf(x => x % 2 === 1)(code),
  // 133 - 135
  code => b(code) < y(code) && y(code) < p(code),
  code => b(code) > y(code) && y(code) > p(code),
  code => !((b(code) > y(code) && y(code) > p(code)) || (b(code) > y(code) && y(code) > p(code))),
  // 136 - 137
  code => b(code) + y(code) > 6,
  code => b(code) + y(code) < 6,
  // 138 - 143
  gt(y, n(4)),
  gt(y, b),
  gt(p, b),
  gt(p, y),
  gt(b, n(4)),
  gt(p, n(4)),
]

function check(code: number, law: number) {
  return laws[law](code);
}

export default check;
