import { Component } from 'react';
import './Loader.css';

type LoaderProps = {
  hash: string,
  gameSettings: number[],
  clickUpdate: (x: number, y: number) => void,
  playGame: () => void,
  tutorialGame: () => void,
};

class Loader extends Component<LoaderProps> {

  render() {

    const title = this.props.hash === "" ? "进行游戏" : `进行游戏 #${this.props.hash}`;

    return (
      <div className="mainTab">
        <h2>{title}</h2>
        <div className="options">

          <div className="radioGroup">
            <input
              type="button"
              className={this.props.gameSettings[0] === 0 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(0, 0)}
              value="经典" />
            <input
              type="button"
              className={this.props.gameSettings[0] === 1 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(0, 1)}
              value="极限" />
            <input
              type="button"
              className={this.props.gameSettings[0] === 2 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(0, 2)}
              value="噩梦" />
          </div>

          <div className="radioGroup">
            <input
              type="button"
              className={this.props.gameSettings[1] === 0 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(1, 0)}
              value="简单" />
            <input
              type="button"
              className={this.props.gameSettings[1] === 1 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(1, 1)}
              value="标准" />
            <input
              type="button"
              className={this.props.gameSettings[1] === 2 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(1, 2)}
              value="困难" />
          </div>

          <div className="radioGroup">
            <input
              type="button"
              className={this.props.gameSettings[2] === 0 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(2, 0)}
              value="4" />
            <input
              type="button"
              className={this.props.gameSettings[2] === 1 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(2, 1)}
              value="5" />
            <input
              type="button"
              className={this.props.gameSettings[2] === 2 ? 'active' : 'inactive'}
              onClick={() => this.props.clickUpdate(2, 2)}
              value="6" />
          </div>
        </div>

        <div id="setting-buttons">
          <input
            id="start-btn"
            type="button"
            value="开始"
            onClick={() => this.props.playGame()}
          />
          <input
            id="tutorial-btn"
            type="button"
            value="开始教程"
            onClick={() => this.props.tutorialGame()}
          />
        </div>
      </div>
    )
  }

}

export default Loader;
