import { Component } from "react";
import "./CodeButton.css";
import getCodeDigit from "./getCodeDigit";

interface CodeButtonProps {
  code: number,
  digit: number,
  num: number,
  setCodeDigit: (a: number, b: number) => void
}

class CodeButton extends Component<CodeButtonProps> {
  render() {
    let classname = "";
    classname += "bigSquare "; // generic class
    classname += "codeButtonColor" + this.props.digit + " "; // color class

    const code = this.props.code
    let r: number[] = getCodeDigit(code); // active class

    classname += (r[this.props.digit] == this.props.num) ? "active" : "";

    return (
      <span
        className={classname}
        onClick={() => this.props.setCodeDigit(this.props.num, this.props.digit)}
      >
        {this.props.num}
      </span>);
  }
}

export default CodeButton;
