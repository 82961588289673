import { Component } from "react";
import { commonTutorial, complexTutorial } from "./tutorials";
import './TutorialTip.css'

interface TutorialTipsProps {
  mode: number,
  st: number,
  incSt: () => void,
}

class TutorialTips extends Component<TutorialTipsProps> {

  render() {

    if (this.props.mode === 0) return (<></>);

    let tt = [] as (string | boolean)[][];

    if (this.props.mode === 1) {
      tt = commonTutorial;
    } else if (this.props.mode === 2) {
      tt = complexTutorial;
    }

    let [tip, cl] = tt[this.props.st];
    let op = cl ? "(点击该文本框以继续)" : "(按照提示要求操作以继续)";

    return (
      <div
        id="tipBlock"
        className="mainTab"
        onClick={() => cl ? this.props.incSt() : () => { }}
      >
        <span id="tutorialTip">{tip}</span><br></br>
        <span id="operationTip"> {op}</span>
      </div>)
  }

}

export default TutorialTips;
