
export const commonTutorial = [
  // 0-5
  ["你好！欢迎进行一次图灵机游戏，这是一款桌游，本站将其简单电子化了，记得支持正版游戏哦！（如果你已经学习过了基础规则并想尝试更高难度的游戏教学，请将上方的难度更改为困难并重新开始一次教学！）", true],
  ["图灵机这个游戏是为了纪念数学家、密码专家艾伦·图灵的贡献。在游戏中，我们将模拟使用原始计算机，破译密码！", true],
  ["游戏目标：找到唯一一个能够通过所有验证的密码，该密码是三位数，均为1-5。游戏中会出现4-6个验证者，例如本次的谜题中出现了4个验证者。", true],
  ["你可以用数字去尝试各个验证者，来找到验证者验证了哪条标准。如果你的尝试与最终答案对于该验证者的回答相同（不严谨），则会得到✔；反之得到✘。", true],
  ["游戏进行数轮，每轮分为4个步骤：组成方案、询问、推理、一轮结束。", true],
  ["组成：现在，我们在下方输入区输入452，并点击“验证”按钮。", false],
  // 6-10
  ["询问：每轮你可以询问最多3个验证者，每个验证者会告知你的方案是否通过。", true],
  ["询问：现在，点击A卡片", false],
  ["询问：很不幸，你得到了✘。由于你的输入中黄色>4，因此在答案中，黄色>4并不满足。现在试试B卡片。", false],
  ["询问：很好！没有数字3，这是一个很不错的结论。再试试C吧。", false],
  ["询问：蓝色<黄色也是满足的！注意，我们不能再验证D了，因为我们每轮最多验证3次。", true],
  // 11-15
  ["推理：我们要根据现在的信息进行推理，我们现在没有紫色的信息，而蓝色黄色已经知道很多了。", true],
  ["推理：黄色不是5，也不是3。因此蓝色和黄色只有 12、14、24 这三种可能", true],
  ["推理：那么我们就可以根据最后一个验证器去验证紫色和蓝色的关系了", true],
  ["一轮结束：实际上我们的操作只有组成和询问，推理是在脑子里（或是纸笔）上完成的。现在点击下一轮", false],
  ["组成：为了验证蓝色和紫色哪个更小，我们输入142并点击验证。", false],
  // 16-20
  ["询问：现在点击D卡片。", false],
  ["询问：我们知道了紫色更小，那么只剩余唯一一种可能了：241。", true],
  ["询问：我们不必完全使用3次验证机会（这在多人游戏中是一个胜利指标），也可以自由地选择先验证哪个卡片，并根据结果选择是否验证或验证哪个卡片。", true],
  ["询问：现在我们已经知道结论了，结束这一轮吧！点击下一轮按钮。", false],
  ["在提交最终结果前，我需要说一句：一般而言猜测错误会导致你直接失败。这里大发慈悲允许你继续猜下去。", true],
  // 21-25
  ["输入241，并点击提交！", false],
  ["我们使用了2轮，共4次询问。多人游玩时，轮数最少的胜利，相同时比较总询问次数。OK！现在你会玩这个游戏了。（不要结束，后面还有）", true],
  ["最后，我要说明这个游戏中重要的两条潜规则：唯一性和有用性。", true],
  ["唯一性：只有一个密码符合标准。例如我们在第一轮验证后，剩余12、14、24三种可能。考虑前两种：蓝色最小，那么我们其实没有其它限制紫色的方法。122、125……都是可以满足条件的解，这破坏了唯一性。因此，我们可以推理得到一定是紫色更小！即我们可以只进行一轮询问！", true],
  ["有用性：任何时候，某个卡片的结论不应该可以直接蕴含另一个卡片的结论。例如，D卡片中，如果蓝色最小，那么毫无疑问C是无用的；类似地，黄色最小也会导致C无用。而任何卡片一定有用！因此，我们可以推理知道紫色最小而无需任何询问！！", true],
  // 26-28
  ["在这种情况下，我们可能可以在第一轮询问中选取更好的问题以减少询问次数。老司机告诉你，这两条潜规则才是游戏的核心！", true],
  ["最后，如果你要开始困难、极限、噩梦的游戏，需要选取相应的教程，它们的规则会有需要说明的地方（但是还没做呢嘿嘿~）", true],
  ["好了！祝你游玩愉快，记得支持正版！教程结束。", true],
];

export const complexTutorial = [
  // 0-5
  ["看来你已经准备好尝试更多的挑战了！（如果你初次接触这个游戏，请在上方选择“经典-标准-4”并重新点击“开始教程”！）", true],
  ["想必你现在已经精通标准难度了，我们不妨试一试“困难”难度，以及“极限”、“噩梦”的模式。", true],
  ["在这些模式中，验证器引入了不确定性：你不知道验证器在验证什么东西。为了理解这一点，让我们从标准的困难模式开始。", true],
  ["观察下面的卡片C，这里提到了验证“特定”的颜色。这意味着该验证者验证的是哪个颜色在该题目中是确定的！", true],
  ["例如，该谜题的答案是411，即C验证的是蓝色等于4。那么在你使用C来验证你的数字时，它只关心蓝色是否为4。", true],
  ["也就是说在你输入141时，即使黄色=4，但是该验证者依旧会提示✘。因此，你的结论应当是“黄色不等于4，或者C验证的不是黄色”。", true],
  // 6-10
  ["你可以进行自由的验证尝试，当你准备好之后，再点击该文本框。", true],
  ["类似地，D和F卡片也只验证特定的颜色。例如在这个谜题中，D和F分别验证黄色和紫色。", true],
  ["这类的卡片让你无法在一次验证中得到足够精确的信息。但是别忘了唯一性！", true],
  ["如果你觉得机器出现问题了，检查一下自己是不是遭遇了X悖论，也就是不确定性导致的错误推断。", true],
  ["或者你可以这样理解：任何一张卡片在一道题中只对应了唯一的法则，该法则是卡片上的2-9个中的一个。验证时只根据你的数字和该法则进行比对，而并不关心卡片的内容。（这个网站就是这么实现的）", true],
  // 11-15
  ["一定要小心X悖论！", true],
  ["下面我们快速的介绍一下极限和噩梦模式。此时，我们需要将卡片和验证者区分开。", true],
  ["在标准模式中，卡片（画有图形的卡牌）和验证者（告诉你对错的机器，用A-F表示，由网站自动实现的功能）是一一对应的。", true],
  ["而在极限模式中，每个验证者对应了两个卡片，验证者只验证其中一个卡片的一条法则，而你不知道是哪一个。", true],
  ["例如下面的例子。你可以自由地试一试数字并进行验证，注意你选择的是验证者（A-D）而非卡片。在你想好答案（或者不想思考）后点击该文本框。", true],
  // 16-20
  ["答案是341！每个验证者对应的正确的卡片都是后面那个。", true],
  ["最后是噩梦模式，你完全不知道每个验证者对应了哪张卡片。想必控制变量法可以帮助你。", true],
  ["自由地试试吧！注意你点击的是下方的验证者（A-D）而非上方的卡片。在你认为推理出答案或决定偷懒后点击该文本框。", true],
  ["答案是142！验证者和卡片是相反顺序摆放的。", true],
  ["现在你已经了解了所有的游戏规则，自由探索吧！", true],
];

