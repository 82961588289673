function getCodeDigit(code: number) {
  let rest = code;
  const p = rest % 10;
  rest = (code - p) / 10;
  const y = rest % 10;
  const b = (rest - y) / 10;
  return [b, y, p];
}

export default getCodeDigit;
