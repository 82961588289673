import { Component } from "react";
import CodeButton from "./CodeButton";
import './InputCode.css';
import getCodeDigit from "./getCodeDigit";

interface InputCodeProps {
  submitCode: (code: number) => void,
  askCode: (code: number) => void,
}

interface InputCodeState {
  code: number;
}

class InputCode extends Component<InputCodeProps, InputCodeState> {

  state = {
    code: 0,
  }

  render() {

    // initialize buttons
    let buttons = [];
    for (let i = 5; i > 0; i--) {
      for (let j = 0; j < 3; j++) {
        buttons.push(
          <CodeButton
            code={this.state.code}
            num={i}
            digit={j}
            setCodeDigit={(number, digit) => this.setCodeDigit(number, digit)}
            key={(j + 1) * 10 + i}
          ></CodeButton>);
      }
      buttons.push(<br key={i} />);
    }

    return (
      <div className="codeInserter">
        <div>
          <span className="blueTriangle"></span>
          <span className="yellowSquare"></span>
          <span className="purpleCircle"></span>
        </div>
        <div className="codeInserter">
          {buttons}
        </div>
        <input className="verify" type="button" value="验证"
          onClick={() => this.props.askCode(this.state.code)} />
        <input className="submit" type="button" value="提交"
          onClick={() => this.props.submitCode(this.state.code)} />
      </div>
    )
  }

  setCodeDigit(num: number, digit: number) {
    var code = this.state.code;

    const [b, y, p] = getCodeDigit(code);

    if (digit === 0) {
      code = num * 100 + y * 10 + p;
    } else if (digit === 1) {
      code = b * 100 + num * 10 + p;
    } else if (digit === 2) {
      code = b * 100 + y * 10 + num;
    }

    this.state.code = code;
    this.forceUpdate();
  }
}

export default InputCode;
